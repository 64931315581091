import React from 'react'

import FAQBlock from './UI/FAQBlock/FAQBlock'

function FAQ() {
  const data = [
    {
      question: 'What is Hoodpay?',
      answer:
        "Hoodpay is a crypto payments processor built to power and scale commerce. Businesses can set up a payment account, start accepting crypto payments, and receive payments directly to their wallet. We're cutting out the middleman.",
    },
    {
      question: 'Why should I accept crypto payments through Hoodpay?',
      answer:
        "A payment is more than just a payment. It's a gateway to growth and that's precisely what we've built. With Hoodpay, you'll save upwards of 70% in fees and say goodbye to network and payout fees in comparison with other leading processing and a checkout page that converts 87% more than leading checkouts.",
    },
    {
      question: 'Where do I receive payouts for crypto payments?',
      answer:
        "Hoodpay is not a custodial solution for crypto payments. This means once you add your cryptocurrency wallet address, you'll receive payments directly without a middleman. You'll save on network fees and won't ever have to worry about your funds being placed under review or frozen for a set period.",
    },
    {
      question: 'How many crypto payments can I accept on Hoodpay?',
      answer:
        'You can accept over 13+ different cryptocurrencies on Hoodpay. We support Bitcoin, Ethereum, Litecoin, Tether, USD Coin, Binance Coin, Solana, Polygon, Tron, Shiba Inu, Dai, Uniswap, Cronos, and ApeCoin.',
    },
    {
      question: 'What level of support is included?',
      answer:
        "Businesses on Hoodpay receive a standard level of support for questions tied to their payments account, API implementation, and general questions. For high-volume businesses, you'll be connected with a dedicated account manager to be your point of contact at Hoodpay.",
    },
    {
      question: 'Can I accept payments through debit/credit cards?',
      answer:
        "Hoodpay supports crypto onramps for select businesses through an invite-only program. Businesses can accept payments through debit/credit cards and bank transfers and get paid out directly in cryptocurrency. If you're interested in our crypto onramp solution, please contact sales@hoodpay.io.",
    },
    {
      question: 'Is Hoodpay safe and secure?',
      answer:
        'Hoodpay is a registered financial technology company in the United States and processes payments for thousands of businesses. Hoodpay is safe for both businesses and customers.',
    },
  ]

  return (
    <section>
      <h2 className="title" style={{ textAlign: 'center', marginBottom: 18 }}>
        What businesses <span className="rational">often ask about</span>
      </h2>
      <p className="text" style={{ textAlign: 'center' }}>
        Still have some questions or want to speak with us?{' '}
        <a className="underlined" href="https://hoodpay.io/discord">
          Join us on discord
        </a>
      </p>
      <div style={{ marginTop: 54 }}>
        {data.map((content) => {
          return <FAQBlock key={content.question} question={content.question} answer={content.answer} />
        })}
      </div>
    </section>
  )
}

export default FAQ
