import React from 'react'

import PromoButtons from '../UI/PromoButtons/PromoButtons'

import cl from './Banner.module.scss'

function Banner() {
  return (
    <section className={cl.banner}>
      <h2 className={cl.title}>
        Level up your crypto game and get paid <br />
        <span className="rational">whenever</span>, <span className="rational">wherever</span>
      </h2>
      <div className={cl.buttons}>
        <PromoButtons dark />
      </div>
    </section>
  )
}

export default Banner
