import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import './styles/scss/fonts.scss'
import './styles/normalize.css'
import './styles/scss/variables.scss'
import './styles/scss/index.scss'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
)
