import React from 'react'
import TypeIt from 'typeit-react'

function Type() {
  // const data = ['Bitcoin', 'Ethereum', 'Litecoin', 'USD Coin', 'Tether', 'Solana', 'Binance Coin']
  // const timing = 4000

  return (
    <TypeIt
      options={{ loop: true, lifeLike: true, speed: 100 }}
      getBeforeInit={(instance) => {
        instance.type('Bitcoin')
        instance.pause(2000)
        instance.delete()
        instance.type('Ethereum')
        instance.pause(2000)
        instance.delete()
        instance.type('Litecoin')
        instance.pause(2000)
        instance.delete()
        instance.type('USD Coin')
        instance.pause(2000)
        instance.delete()
        instance.type('Tether')
        instance.pause(2000)
        instance.delete()
        instance.type('Solana')
        instance.pause(2000)
        instance.delete()
        instance.type('Binance Coin')
        instance.pause(2000)
        instance.delete()

        return instance
      }}
    />
  )
}

export default Type
