import React from 'react'

import cl from './FeedbackCard.module.scss'

function FeedbackCard({title, review, author}) {
  return (
    <div className={cl.feedbackCard}>
      <div className={cl.starRating}>
        <img src="images/icons/star.svg" alt="Star filled" />
        <img src="images/icons/star.svg" alt="Star filled" />
        <img src="images/icons/star.svg" alt="Star filled" />
        <img src="images/icons/star.svg" alt="Star filled" />
        <img src="images/icons/star.svg" alt="Star filled" />
      </div>
      <span className={cl.title}>{title}</span>
			<p className={cl.review}>{review}</p>
			<span className={cl.author}>{author}</span>
    </div>
  )
}

export default FeedbackCard
