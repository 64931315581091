import React from 'react'
import { Link } from 'react-router-dom'

import cl from './Logo.module.scss'

function Logo({dark, height, width}) {
  return (
    <Link to="/" className={cl.logo}>
      <svg width={width ? `${width}` : "193"} height={height ? `${height}` : "35"} viewBox="0 0 193 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 138103">
          <g id="Vector">
            <path d="M10.4478 6.82043L12.1522 29.7572L1.70443 29.1397L0 6.20299L10.4478 6.82043Z" fill={dark ? "#191919" : "#A7E23D"} />
            <path d="M16.5246 12.5462L18.229 35.4829L7.78121 34.8655L6.07678 11.9288L16.5246 12.5462Z" fill={dark ? "#191919" : "#A7E23D"} />
            <path d="M19.7974 1.38648L21.5018 24.3232L11.054 23.7058L9.34962 0.769043L19.7974 1.38648Z" fill={dark ? "#191919" : "#A7E23D"} />
          </g>
          <g id="HOODPAY">
            <path d="M31.4355 26.4588H37.5109V19.8248H46.7053V26.4588H52.7806V9.79238H46.7053V15.7048H37.5109V9.79238H31.4355V26.4588Z" fill="#191919" />
            <path
              d="M66.9005 27.0407C74.2328 27.0407 79.121 23.4793 79.121 18.1256C79.121 12.7718 74.2328 9.21045 66.9005 9.21045C59.5915 9.21045 54.7033 12.7718 54.7033 18.1256C54.7033 23.4793 59.5915 27.0407 66.9005 27.0407ZM66.9005 22.7577C63.246 22.7577 60.7786 20.8955 60.7786 18.1256C60.7786 15.3556 63.246 13.4934 66.9005 13.4934C70.555 13.4934 73.0457 15.3556 73.0457 18.1256C73.0457 20.8955 70.555 22.7577 66.9005 22.7577Z"
              fill="#191919"
            />
            <path
              d="M92.8372 27.0407C100.169 27.0407 105.058 23.4793 105.058 18.1256C105.058 12.7718 100.169 9.21045 92.8372 9.21045C85.5282 9.21045 80.64 12.7718 80.64 18.1256C80.64 23.4793 85.5282 27.0407 92.8372 27.0407ZM92.8372 22.7577C89.1827 22.7577 86.7153 20.8955 86.7153 18.1256C86.7153 15.3556 89.1827 13.4934 92.8372 13.4934C96.4917 13.4934 98.9823 15.3556 98.9823 18.1256C98.9823 20.8955 96.4917 22.7577 92.8372 22.7577Z"
              fill="#191919"
            />
            <path
              d="M106.972 26.4588H118.82C125.291 26.4588 128.899 23.4793 128.899 18.1256C128.899 12.7718 125.291 9.79238 118.82 9.79238H106.972V26.4588ZM112.885 22.3387V13.8891H117.982C121.195 13.8891 123.01 15.4254 123.01 18.1256C123.01 20.8257 121.195 22.3387 117.982 22.3387H112.885Z"
              fill="#191919"
            />
            <path
              d="M130.84 26.4588H136.916V21.3611H144.225C148.973 21.3611 151.604 19.336 151.627 15.6582C151.627 11.8873 148.997 9.79238 144.271 9.79238H130.84V26.4588ZM136.916 17.9393V13.2141H142.246C144.364 13.2141 145.528 14.0521 145.528 15.5651C145.528 17.1014 144.364 17.9393 142.246 17.9393H136.916Z"
              fill="#191919"
            />
            <path
              d="M149.613 26.4588H155.897L157.178 23.5491H165.488L166.791 26.4588H173.099L164.976 9.79238H157.923L149.613 26.4588ZM158.365 20.1274L161.344 13.54H161.391L164.324 20.1274H158.365Z"
              fill="#191919"
            />
            <path
              d="M178.125 26.4588H184.201V20.7792L192.185 9.79238H185.714L181.198 16.8221H181.175L176.426 9.79238H170.025L178.125 20.7792V26.4588Z"
              fill="#191919"
            />
          </g>
        </g>
      </svg>
    </Link>
  )
}

export default Logo
