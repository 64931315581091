import React from 'react'
import Type from '../Type'

import PromoButtons from '../UI/PromoButtons/PromoButtons'

import cl from './PromoMain.module.scss'

function PromoMain() {

  return (
    <div className={cl.promoMain}>
      <div className={cl.container}>
        <h1 className={cl.title}>
          Accept{' '}
          <span className="bolded">
            <Type/>
          </span>
          <br /> with <span className="underlined-custom">zero</span> network fees
        </h1>
        <p className={cl.text}>
          Thousands of&nbsp;internet businesses use Hoodpay&rsquo;s customer-to-business software and infrastructure to&nbsp;accept direct crypto payments.
        </p>
        <div className={cl.buttons}>
          <PromoButtons />
        </div>
      </div>
      <img className={cl.dashboard} src="images/header/header.svg" alt="dashboard" />
    </div>
  )
}

export default PromoMain
