import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../UI/Logo/Logo'
import Button from '../UI/Button/Button'
import ButtonBlack from '../UI/Button/ButtonBlack'

import cl from './Header.module.scss'

function Header({ bg, children }) {
  const [menuOpen, setMenuOpen] = React.useState(false)

  const navClasses = [cl.nav]
  const overflowClasses = [cl.overflow]
  const burgerClasses = [cl.burger]

  if (menuOpen) {
    navClasses.push(cl.navActive)
    overflowClasses.push(cl.overflowActive)
    burgerClasses.push(cl.burgerActive)
  }

  const overflowClicked = () => {
    setMenuOpen(!menuOpen)
  }

  const headerClasses = [cl.header]

  if (bg === 'pricing') {
    headerClasses.push(cl.headerPricing)
  }

  return (
    <>
      <div className={overflowClasses.join(' ')} onClick={overflowClicked}></div>
      <header className={headerClasses.join(' ')}>
        <div className={cl.headerTop}>
          <Logo width={193} />
          <nav className={navClasses.join(' ')}>
            <ul className={cl.nav__list}>
              <li className={cl.nav__item}>
                {bg === 'pricing' ? (
                  <Link to="/">Features</Link>
                ) : (
                  <a
                    href="#about"
                    onClick={() => {
                      if (menuOpen) {
                        setMenuOpen(!menuOpen)
                      }
                    }}>
                    Features
                  </a>
                )}
              </li>
              <li className={cl.nav__item}>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li className={cl.nav__item}>
                <a href="https://docs.hoodpay.io">Developers</a>
              </li>
              <li className={cl.nav__item}>
                <a href="https://docs.hoodpay.io/docs/welcome-to-hoodpay">Support</a>
              </li>
            </ul>
            <div className={cl.authorizationMobile}>
              <Button link="https://dash.hoodpay.io/auth/register">Sign up</Button>
              <ButtonBlack link="https://dash.hoodpay.io/auth/login">Sign in</ButtonBlack>
            </div>
          </nav>
          <div className={cl.authorization}>
            <Button link="https://dash.hoodpay.io/auth/register">Sign up</Button>
            <ButtonBlack link="https://dash.hoodpay.io/auth/login">Sign in</ButtonBlack>
          </div>
          <div className={burgerClasses.join(' ')} onClick={() => setMenuOpen(!menuOpen)}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        {children}
      </header>
    </>
  )
}

export default Header
