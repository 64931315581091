import React from 'react'

import Logo from '../UI/Logo/Logo'

import cl from './Footer.module.scss'

function Footer() {
  return (
    <footer className={cl.footer}>
      <Logo dark height={28} width={150}/>
      <div className={cl.wrapper}>
        <div className={cl.main}>
          <p className={cl.copyright}>
            Copyright © 2023 Hoodpay LLC. All rights reserved. Hoodpay is a registered financial technology company. 1309 Coffeen Avenue Ste 8393 Sheridan, WY
            82801.
          </p>
          <p className={cl.policy}>
            For law enforcement requests, please review our <a href="/" className='underlined'>request for information policy.</a>
          </p>
        </div>
        <nav className={cl.nav}>
          <ul className={cl.nav__list}>
            <li className={cl.nav__item}>
              <a href="https://hoodpay.io/linkedin">LinkedIn</a>
            </li>
            <li className={cl.nav__item}>
              <a href="https://twitter.com/hoodpayio">Twitter</a>
            </li>
            <li className={cl.nav__item}>
              <a href="https://hoodpay.io/discord">Discord</a>
            </li>
          </ul>
          <ul className={cl.nav__list}>
            <li className={cl.nav__item}>
              <a href="https://hoodpay.io/discord">Contact us</a>
            </li>
            <li className={cl.nav__item}>
              <a href="https://status.hoodpay.io">Platform Status</a>
            </li>
            <li className={cl.nav__item}>
              <a href="https://docs.hoodpay.io">Developer Docs</a>
            </li>
          </ul>
          <ul className={cl.nav__list}>
            <li className={cl.nav__item}>
              <a href="https://docs.hoodpay.io/docs/platform-agreement">Platform Agreement</a>
            </li>
            <li className={cl.nav__item}>
              <a href="https://docs.hoodpay.io/docs/api-agreement">API Agreement</a>
            </li>
            <li className={cl.nav__item}>
              <a href="https://docs.hoodpay.io/docs/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default Footer
