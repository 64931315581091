import React from 'react'

import cl from './FAQBlock.module.scss'

function FAQBlock({question, answer}) {
	const classes = [cl.faqBlock]

  const [faqOpen, setFaqOpen] = React.useState(false)

	if (faqOpen) {
		classes.push(cl.faqBlockActive)
	}

  return (
    <div className={classes.join(' ')} onClick={() => setFaqOpen(!faqOpen)}>
      <div className={cl.question}>
        <h3 className={cl.question__text}>{question}</h3>
      </div>
      <div className={cl.answer}>
        <p className={cl.answer__text}>{answer}</p>
      </div>
      <button className={cl.openBtn} />
    </div>
  )
}

export default FAQBlock
