import React from 'react'

import cl from './Platform.module.scss'

function Platform() {
  const data = [
    {
      title: 'Crypto onramp',
      additional: '(INVITE-ONLY)',
      descr: 'Accept payments from debit/credit cards and receive crypto.',
    },
    {
      title: 'Zero network fees',
      descr: 'Unlock more of your funds by accepting crypto directly.',
    },
    {
      title: '1-click payments',
      descr: 'Let customers checkout in minutes with crypto.',
    },
    {
      title: 'E-commerce checkout',
      descr: 'Sell digital products and get paid with popular cryptocurrencies.',
    },
  ]

  return (
    <section className={cl.platform}>
      <span className={cl.subtitle}>Platform</span>
      <h2 className={cl.title}>Payments infrastructure for crypto commerce</h2>
      <div className={cl.featuresWrapper}>
        {data.map((content) => {
          return (
            <div className={cl.feature} key={content.title}>
              <div>
                <h3 className={cl.feature__title}>{content.title}</h3>
                {content.additional ? <span className={cl.additional}>{content.additional}</span> : ''}
              </div>
              <p className={cl.feature__descr}>{content.descr}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Platform
