import React from 'react'

import cl from './Statistics.module.scss'

function Statistics() {
  const data = [
    {
      title: '10+',
      text: 'Popular cryptocurrencies available for direct payments.',
    },
    {
      title: '0%',
      text: 'Risk of payments being reversed or disputed.',
    },
    {
      title: '2min',
      text: 'Average time it takes to set up and start getting paid.',
    },
    {
      title: '1,000+',
      text: 'Businesses process crypto payments through Hoodpay.',
    },
  ]

  return (
    <section className={cl.statistics}>
      <div className={cl.wrapper}>
        {data.map((content) => {
          return (
            <div className={cl.item} key={content.title}>
              <span className={cl.title}>{content.title}</span>
              <p className={cl.text}>{content.text}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Statistics
