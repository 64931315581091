import React from 'react'

import Advantage from '../UI/Advantage/Advantage'
import ViewMoreLink from '../UI/ViewMoreLink/ViewMoreLink'

import cl from './About.module.scss'

function About() {
  const data = [
    {
      title: 'Branded Checkout',
      descr: 'Customize the checkout experience with your logo, name, and support contact.',
    },
    {
      title: 'Mobile Optimized',
      descr: 'Sell to customers with with a mobile-first approach to paying through crypto.',
    },
    {
      title: 'Multiple Cryptocurrencies',
      descr: 'Accept a variety of popular cryptocurrencies from Bitcoin, Ethereum, Litecoin, and more.',
    },
    {
      title: 'Customer Receipts',
      descr: 'Automatically send receipts to customers after payments for post-customer experiences.',
    },
  ]

  return (
    <section className={cl.about} id="about">
      <div>
        <h2 className={cl.title}>
          Accept <span className="rational">crypto payments</span>
          <br /> with one-click
        </h2>
        <p className={cl.descr}>
          Unrivaled payments processing with near-instant payment confirmations, Hoodpay helps your business scale through the power of crypto automation.
        </p>
        <div className={cl.features}>
          {data.map((content) => {
            return <Advantage key={content.title} title={content.title} descr={content.descr} />
          })}
        </div>
        <ViewMoreLink link="https://checkout.hoodpay.io">View demo checkout page</ViewMoreLink>
      </div>
      <div className={cl.picture}>
        <img src="images/about/about.svg" alt="Web App Interface" />
      </div>
    </section>
  )
}

export default About
