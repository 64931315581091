import React from 'react'

import cl from './Partners.module.scss'

function Partners() {
  return (
    <section className={cl.partners}>
      <div>
        <img src="images/partners/partners-logo-1.svg" alt="Alchemy" />
      </div>
      <div>
        <img src="images/partners/partners-logo-2.svg" alt="Ledger" />
      </div>
      <div>
        <img src="images/partners/partners-logo-3.svg" alt="Sellpass" />
      </div>
      <div>
        <img src="images/partners/partners-logo-4.svg" alt="Trezor" />
      </div>
      <div>
        <img src="images/partners/partners-logo-5.svg" alt="Ankr" />
      </div>
      <div>
        <img src="images/partners/partners-logo-6.svg" alt="Twilio" />
      </div>
    </section>
  )
}

export default Partners
