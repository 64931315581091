import React from 'react'

import Advantage from '../UI/Advantage/Advantage'
import ViewMoreLink from '../UI/ViewMoreLink/ViewMoreLink'

import cl from './About.module.scss'

function AboutSecond() {
  const data = [
    {
      title: 'Transaction Details',
      descr: 'Access dates, customer emails, checkout links and fees within a single dashboard.',
    },
    {
      title: 'Payment Logs',
      descr: 'View status timelines, add internal notes, customize metadata, webhook logs, and more.',
    },
  ]

	const aboutClasses = [cl.about, cl.aboutSecond]

  return (
    <section className={aboutClasses.join(' ')}>
			<div className={cl.picture}>
        <img src="images/about/about2.svg" alt="Web App Interface" />
      </div>
      <div>
        <h2 className={cl.title}>
          Infinite data, immense <br /> profit, <span className="rational">near-zero fees</span>
        </h2>
        <p className={cl.descr}>
          We help you unlock up to 70% of your funds by dropping third-party processors that overcharge for crypto payments all in a simple & beautiful business
          dash.
        </p>
        <div className={cl.features}>
          {data.map((content) => {
            return <Advantage key={content.title} title={content.title} descr={content.descr} />
          })}
        </div>
        <ViewMoreLink link='https://dash.hoodpay.io/auth/register'>Create a business account</ViewMoreLink>
      </div>
    </section>
  )
}

export default AboutSecond
