import React from 'react'
import { Route, Routes } from 'react-router-dom'
// UI
import Tip from './components/Tip/Tip'
// Components
import FAQ from './components/FAQ'
import Footer from './components/Footer/Footer'
import Home from './pages/Home'
import Pricing from './pages/Pricing'

function App() {
  return (
    <>
      <Tip />
      <div className="wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>

        <FAQ />
        <Footer />
      </div>
    </>
  )
}

export default App
