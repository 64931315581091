import React from 'react'
import FeedbackCard from '../UI/FeedbackCard/FeedbackCard'

import cl from './Reviews.module.scss'

function Reviews() {
  const data = [
    {
      title: 'Great team behind Hoodpay, love them!',
      review: 'If you’re accepting crypto, I highly suggest Hoodpay not only for how fast and simple I get my money but because of their team. If I need help or any question, they’re always there to help. That’s one thing that keeps me here than other platforms.',
      author: 'Mark',
    },
    {
      title: 'Hoodpay is the world’s best crypto processor',
      review: 'We moved to Hoodpay a few days ago and the difference in how our customers responded to the checkout and the ease of payments has been something we’re very proud of. Props to everyone at Hoodpay!',
      author: 'Cameron',
    },
    {
      title: 'An ultimate godsend for my online store',
      review: 'Before Hoodpay, we used a couple of different platforms and nothing really helped us save in fees. Then a friend suggested I switch over, and the experience so far has taken pressure off from where we’d need to accept payments from. We love what Hoodpay has built.',
      author: 'Mohammed',
    },
  ]

  return (
    <section className={cl.reviews}>
      <div className={cl.wrapper}>
        <h2 className="title" style={{ textAlign: 'center', marginBottom: 16 }}>
          Customer love from <span className="bolded">first payment</span>
        </h2>
        <p className="text" style={{ textAlign: 'center',  maxWidth: 1225}}>
				Your customers will experience a&nbsp;smoother, carefully designed, and branded checkout that converts&nbsp;87% more than other leading checkouts.
        </p>
        <div className={cl.feedbackWrapper}>
          {data.map((content) => {
            return <FeedbackCard key={content.title} title={content.title} review={content.review} author={content.author} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Reviews
