import React from 'react'

import Meta from '../components/Meta'

import Header from '../components/Header/Header'
import PromoMain from '../components/PromoMain/PromoMain'
import Partners from '../components/Partners/Partners'
import Features from '../components/Features/Features'
import Platform from '../components/Platform/Platform'
import About from '../components/About/About'
import AboutSecond from '../components/About/AboutSecond'
import AboutThird from '../components/About/AboutThird'
import Statistics from '../components/Statistics/Statistics'
import Banner from '../components/Banner/Banner'
import Reviews from '../components/Reviews/Reviews'

function Home() {
  return (
    <>
			<Meta title={'Hoodpay: Accept crypto payments with zero network fees'}/>
      <Header>
        <PromoMain />
      </Header>
      <Partners />
      <Features />
      <About />
      <Platform />
      <AboutSecond />
      <Statistics />
      <AboutThird />
      <Reviews />
      <Banner />
    </>
  )
}

export default Home
