import React from 'react'

import Button from '../Button/Button'
import ButtonBlack from '../Button/ButtonBlack'

import cl from './PromoButtons.module.scss'

function PromoButtons({dark}) {
	return (
		<div className={cl.promoButtonsBlock}>
			<ButtonBlack dark={dark} arrow large link='https://dash.hoodpay.io/auth/register'>Start now</ButtonBlack>
			<Button dark={dark} arrow link='https://hoodpay.io/discord'>Contact sales</Button>
		</div>
	)
}

export default PromoButtons