import React from 'react'

import cl from './CurrencyTable.module.scss'

function CurrencyTable() {
  const cryptoData = [
    {
      logo: 'images/crypto/btc.svg',
      name: 'Bitcoin (BTC)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $9.99 USD',
    },
    {
      logo: 'images/crypto/sol.svg',
      name: 'Solana (SOL)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $9.99 USD',
    },
    {
      logo: 'images/crypto/eth.svg',
      name: 'Ethereum (ETH)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $9.99 USD',
    },
    {
      logo: 'images/crypto/usdt.svg',
      name: 'Tether (USDT)',
      additional: 'Networks: ETH, TRX',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/usdc.svg',
      name: 'USD Coin (USDC)',
      additional: 'Networks: ETH, TRX',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/bnb.svg',
      name: 'Binance Coin (BNB)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/matic.svg',
      name: 'Polygon (MATIC)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/shiba.svg',
      name: 'Shiba Inu (SHIBA)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/trx.svg',
      name: 'Tron (TRX)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/dai.svg',
      name: 'Dai (DAI)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/uni.svg',
      name: 'Uniswap (UNI)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/cro.svg',
      name: 'Cronos (CRO)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
    {
      logo: 'images/crypto/ape.svg',
      name: 'ApeCoin (APE)',
      networkFee: '0%',
      processingFee: '0.25% - 1%',
      fixedFee: '$0.10 over $4.99 USD',
    },
  ]

  return (
    <section className={cl.currencyTable}>
      <div className={cl.table}>
        <div className={cl.row}>
          <div>Cryptocurrency</div>
          <div>Network fee</div>
          <div>Processing fee</div>
          <div>Fixed fee</div>
        </div>
        {cryptoData.map((content) => {
          return (
            <div className={cl.row} key={content.name}>
              <div className={cl.name}>
                <img src={content.logo} width={35} height={35} alt="currency logo" />
                <div>
                  {content.name}
                  {content.additional ? <span>{content.additional}</span> : ''}
                </div>
              </div>
              <div>{content.networkFee}</div>
              <div>{content.processingFee}</div>
              <div>{content.fixedFee}</div>
            </div>
          )
        })}
      </div>
      <p className={cl.text}>
        For high-volume businesses, we may be able to reduce your fees if you're switching over to Hoodpay from any other crypto payment processor.{' '}
        <a href='https://hoodpay.io/discord' className="underlined">Contact sales →</a>
      </p>
    </section>
  )
}

export default CurrencyTable
