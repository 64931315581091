import React from 'react'

import Advantage from '../UI/Advantage/Advantage'
import ViewMoreLink from '../UI/ViewMoreLink/ViewMoreLink'

import cl from './About.module.scss'

function AboutThird() {
  const data = [
    {
      title: 'Webhooks',
      descr: 'Listen to webhooks and create events to seamlessly deliver services and products.',
    },
    {
      title: 'Event Logs',
      descr: 'Unlock enterprise-grade event logs to both requests and responses tied to the payment.',
    },
    {
      title: 'Detailed Docs',
      descr: 'Start collecting payments on your own site in less than 4 minutes with our documentation.',
    },
    {
      title: 'Errors',
      descr: 'Clear and vetted error handling with status codes and response data.',
    },
  ]

	const aboutClasses = [cl.about, cl.aboutThird]

  return (
    <section className={aboutClasses.join(' ')}>
      <div>
        <h2 className={cl.title}>
          A <span className="rational">powerful</span>, easy-to-use <br /> payments API
        </h2>
        <p className={cl.descr}>
          When your engineers save time and energy with our developer-first approach, you’ll see unrivaled customer interactions for your business.
        </p>
        <div className={cl.features}>
          {data.map((content) => {
            return <Advantage key={content.title} title={content.title} descr={content.descr} />
          })}
        </div>
        <ViewMoreLink link='https://docs.hoodpay.io'>View developer docs</ViewMoreLink>
      </div>
			<div className={cl.picture}>
        <img src="images/about/about3.svg" alt="Web App Interface" />
      </div>
    </section>
  )
}

export default AboutThird
