import React from 'react'
import { Helmet } from 'react-helmet'

function Meta({ title }) {
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content="Hoodpay is a crypto payments processor built to power and supercharge commerce. Thousands of internet businesses use Hoodpay’s customer-to-business software and infrastructure to accept direct crypto payments."
      />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://hoodpay.io/" />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content="Hoodpay is a crypto payments processor built to power and supercharge commerce. Thousands of internet businesses use Hoodpay’s customer-to-business software and infrastructure to accept direct crypto payments."
      />
      <meta property="og:image" content="https://hoodpay.io/images/HoodpayAd.png" />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://hoodpay.io/" />
      <meta property="twitter:title" content={title} />
      <meta
        property="twitter:description"
        content="Hoodpay is a crypto payments processor built to power and supercharge commerce. Thousands of internet businesses use Hoodpay’s customer-to-business software and infrastructure to accept direct crypto payments."
      />
      <meta property="twitter:image" content="https://hoodpay.io/images/HoodpayAd.png" />
    </Helmet>
  )
}

export default Meta
