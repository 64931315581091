import React from 'react'

import cl from './Advantage.module.scss'

function Advantage({title, descr}) {
  return (
    <div key={title} className={cl.wrapper}>
      <div className={cl.titleWrapper}>
        <div className={cl.icon}></div>
        <h3 className={cl.title}>{title}</h3>
      </div>
      <p className={cl.descr}>{descr}</p>
    </div>
  )
}

export default Advantage
