import React from 'react'

import PromoButtons from '../UI/PromoButtons/PromoButtons'

import cl from './PromoPricing.module.scss'

function PromoPricing() {
  return (
    <div className={cl.promoPricing}>
      <h1 className={cl.title}>
        Clear, <span className="underlined-custom">per-transaction</span> pricing
      </h1>
      <p className={cl.text}>No hidden fees, no lock-in contracts, and no monthly costs. With Hoodpay, you only pay for successful crypto payments.</p>
      <div className={cl.buttons}>
        <PromoButtons />
      </div>
    </div>
  )
}

export default PromoPricing
