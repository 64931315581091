import React from 'react'

import Meta from '../components/Meta'
import CurrencyTable from '../components/CurrencyTable/CurrencyTable'
import Header from '../components/Header/Header'
import PromoPricing from '../components/PromoPricing/PromoPricing'

function Pricing() {
  return (
    <>
      <Meta title='Hoodpay: Pricing' />
      <Header bg={'pricing'}>
        <PromoPricing />
      </Header>
      <CurrencyTable />
    </>
  )
}

export default Pricing
