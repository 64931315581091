import React from 'react'

import cl from './Tip.module.scss'

function Tip() {
	return (
		<div className={cl.tipBlock}>
			<p className={cl.tipText}>
				Switching over to Hoodpay with <span className='recklessneue'>high-transaction volume</span>? <a href='https://hoodpay.io/discord' className='underlined'>Contact sales</a> to unlock an exclusive fee package as low as 0.25%.
			</p>
		</div>
	)
}

export default Tip